<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{$t("g.condition")}}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">租用时间</span>
          <el-date-picker
                class="inp"
                v-model="formData.valTime"
                size="small"
                type="daterange"
                unlink-panels
                :range-separator="$t('g.to')"
                :start-placeholder="$t('g.start_date')"
                :end-placeholder="$t('g.end_date')"
                :shortcuts="render.shortcuts"
              >
              </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{$t('g.operator')}}</span>
          <el-select class="inp" size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.operators"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">租用状态</span>
          <el-select class="inp" size="small" v-model="formData.valRentStatus" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.rentStatuses"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">归还状态</span>
          <el-select class="inp" size="small" v-model="formData.valReturnStatus" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.returnStatuses"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">手机号码</span>
          <el-input class="inp" size="small" v-model="formData.valTel" placeholder="请输入手机号码"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{$t('g.search')}}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{$t("g.unit_num")}}:{{formData.total}}，{{$t("g.per_page")}}:{{formData.pageSize}}</span>
      </div>
      <el-table
            @row-click="func.rowDblClick"
            :data="formData.tableData"
            style="width: 100%">
            <el-table-column
              prop="id"
              label="#"
              width="30">
            </el-table-column>
            <el-table-column
              prop="name"
              label="会员"
              width="100">
            </el-table-column>
            <el-table-column
              prop="no"
              label="车牌号"
              width="150">
            </el-table-column>
            <el-table-column
              prop="code"
              label="车辆编号"
              width="150">
            </el-table-column>
            <el-table-column
              prop="rent_type"
              label="租用方式"
              width="100">
            </el-table-column>
            <el-table-column
              prop="rent_status"
              label="租用状态"
              width="100">
            </el-table-column>
            <el-table-column
              prop="return_status"
              label="归还状态"
              width="100">
            </el-table-column>
            <el-table-column
              prop="rent"
              label="租金"
              width="120">
            </el-table-column>
            <el-table-column
              prop="start"
              label="开始时间">
            </el-table-column>
            <el-table-column
              prop="end"
              label="结束时间">
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :total="formData.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
export default {
  name: 'MemberBikes',
  setup () {
    const tableData = [
      // {
      //   id: 1,
      //   operator: '广东深圳',
      //   name: '小王',
      //   no: 'B123Z3',
      //   code: 'XBSDF324234',
      //   rent_type: '扫码',
      //   rent_status: '未到期',
      //   return_status: '已归还',
      //   rent: '100.21',
      //   start: '2021-08-11 12:12:12',
      //   end: '2021-08-11 12:12:12'
      // }
    ]
    const shortcuts = [
      {
        text: '最近一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        }
      }, {
        text: '最近一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        }
      }, {
        text: '最近三个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        }
      }
    ]
    const rentStatuses = [
      {
        label: '所有状态',
        value: 0
      },
      {
        label: '已到期',
        value: 1
      },
      {
        label: '未到期',
        value: 2
      }
    ]
    const returnStatuses = [
      {
        label: '所有状态',
        value: 0
      },
      {
        label: '已归还',
        value: 1
      },
      {
        label: '未归还',
        value: 2
      }
    ]
    const operators = [
      {
        label: '广东深圳',
        value: 0
      },
      {
        label: '北京朝阳',
        value: 1
      }
    ]
    const render = {
      operators,
      rentStatuses,
      returnStatuses,
      shortcuts
    }
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valRentStatus: 0,
      valReturnStatus: 0,
      valTel: '',
      valOperator: 0,
      dialogVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 0
    })
    const func = {
      search () {
        console.log('formData', formData)
      },
      rowDblClick (row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange (val) {
        console.log('page change', val)
        formData.curPage = val
      }
    }
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      >.title{
        font-size:1rem;
        font-weight: 500;
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 4.375rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 0.875rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
  }
}
</style>

<template>
    <div class="activity-content">
        <div class="phone">
            <div class="activity-title">
                {{propObj.title}}
            </div>
            <div class="activity-time">
                活动时间：{{propObj.startTime[0]}}至{{ propObj.startTime[1] }}
            </div>
            <div class="award">
                <!-- <div class="award-title">
                奖励什么
            </div> -->
                <div class="award-content">
                    <div class="award-content-title">{{propObj.awardType}}</div>
                    <div class="award-content-reality">{{propObj.awardTime}}{{propObj.awardUnit}}</div>
                    <div class="award-name">邀请一个注册用户奖励</div>
                </div>
            </div>
            <div class="process">
                <div class="process-title">奖励自动发放</div>
                <div class="steps">
                    <div class="step1">微信分享给好友，或展示二维码</div>
                    <div class="step2">好友点击分享，注册使用7天后发放</div>
                </div>
            </div>
            <div class="earnings">
                <div class="earnings-title">
                    我的收益
                </div>
                <div class="earnings-content">
                    <div class="left">
                        <div class="num">{{ propObj.myEarnings }}</div>
                        <div class="detail">邀请收益(分钟)</div>
                    </div>
                    <div class="right">
                        <div class="num">{{ propObj.inviteNum }}</div>
                        <div class="detail">邀请人数(个)</div>
                    </div>
                </div>
            </div>
            <div class="share-btn"><div>面对面邀请</div><div>微信分享</div></div>
            <div class="rule" v-show="propObj.rule">活动规则</div>
        </div>
        <div class="style-name"> {{propObj.styleName}} </div>
    </div>
</template>
<script>
import { reactive, onMounted, computed } from 'vue'
import i18n from '@/data/i18n/index'
export default {
    name: 'activityStyle',
    props: {
        propObj:{
            type:Object,
            default(){
            }
            
        }
      

    },
    computed:{
        propObj(){
            return Object.assign({
                title:"邀好友，得大奖",
                startTime:['2023-11-21','2023-12-21'],
                endTime:"2023/12/21",
                awardTime:'0',
                myEarnings:'0',
                inviteNum:'0',
                awardUnit:'分钟',
                awardType:'换电时间',
                styleName:'',
                rule:''
            },this.propObj)
        }
    },
    setup(props) {
        const t = i18n.global.t
        const render = {}
        const formData = reactive({})
        const func = {}
        onMounted(() => {
            // func.loadData()
        })
        return {
        }
    }
}
</script>


<style lang="scss" scoped>
.activity-content {
    // position: absolute;
    // right:10px;
    // top:0px;
    
    .phone {
        width: 330px;
        height: 630px; 
        // overflow-y: auto;
        transform: scale(0.8);
        margin: -20% 0;
        background: linear-gradient(to bottom, #3a81ff 0%, #9abcf7 30%, #e7eefa 100%);
        // margin-left: 100px;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        position: relative;
        .activity-title {
            font-size: 30px;
            text-align: center;
            margin-top: 10px;
            color: #fff;
            -webkit-background-clip: text;
            background-clip: text;
            min-height: 40px;
        }

        .activity-time {
            margin-top: 10px;
            padding: 13px;
            color: #e8fdff;
            background-color: rgba(87, 154, 255, 1);
            background: linear-gradient(to right, rgba(87, 154, 255, .1) 0%, rgba(87, 154, 255, 0.8) 50%, rgba(87, 154, 255, .1) 100%);
            font-size: 14px;
            border-bottom: 2px solid;
            border-top: 2px solid;
            border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #d6f1ff 50%, rgba(255, 255, 255, 0) 99%) 1 1 1 1;
            border-radius: 5px;
        }
        .award {
            width: 83%;
            margin-top: 15px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .award-title {
            font-size: 22px;
            color: #784d47;
        }
        .award-content {
            width: 100%;
            height: 130px;
            padding: 0 15px;
            border-radius: 10px;
            background-color: #fffaf5;
            -webkit-mask-image: radial-gradient(circle at 10px 40px, transparent 10px, red 10.5px), radial-gradient(closest-side circle at 50%, red 10%, transparent 100%);
            -webkit-mask-size: 100%, 12px 4px;
            -webkit-mask-repeat: repeat, repeat-x;
            -webkit-mask-position: -10px, 50% 40px;
            -webkit-mask-composite: source-out;
            mask-composite: subtract;
        }
        .award-content-title {
            height: 40px;
            text-align: center;
            line-height: 40px;
            color: #7a4940;
            font-size: 20px;
        }
        .award-content-reality {
            height: 50px;
            text-align: center;
            line-height: 60px;
            color: #e53357;
            font-size: 25px;
        }
        .award-name {
            height: 20px;
            color: #461910;
            line-height: 35px;
            font-size: 16px;
            text-align: center;
        }
        }
       
        .rule {
            position: absolute;
            right: 0;
            top: 0;
            writing-mode: vertical-rl;
            letter-spacing: 3px;
            padding: 5px 3px;
            color: #ddf4ff;
            font-size: 14px;
            background-color: #416fc0;
            border-radius: 3px;
        }
        .process {
            width: 83%;
            margin-top: 15px;
            background-color: #fffaf5;
            padding: 15px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .process-title {
                font-size: 22px;
                color: #784d47;
            }
            .steps {
            color: #6e4037;
            font-size: 15px;
            // display: flex;
            // flex-direction: column;
            text-align: left;
            .step1,
            .step2 {
                margin-top: 5px;
            }
            .step1::before {
            content: '1';
            color: #fff9fc;
            /* background-color: #fd5570; */
            background: linear-gradient(to bottom, #fd5570 0%, #f994a5 100%);
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 15px;
            margin-right: 10px;
        }

        .step2::before {
            content: '2';
            color: #fff9fc;
            /* background-color: #fd5570; */
            background: linear-gradient(to bottom, #fd5570 0%, #f994a5 100%);
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 15px;
            margin-right: 10px;
        }
        }
        }
        
      
      
  

        .earnings {
            width: 83%;
            margin-top: 15px;
            padding: 15px;
            background-color: #fffaf5;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .progress-title,
        .earnings-title {
            font-size: 22px;
            color: #784d47;
        }

            .earnings-content {
            margin-top: 15px;
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .left,
        .right {
            flex: 1;
            font-size: 15px;
            text-align: center;
        }

        .right {
            flex: 1;
            border-left: 2px solid #f3cec0;
            /* padding: 20px; */
            /* background-color: pink; */
        }

        .num {
            font-size: 30px;
            color: #845146;
        }

        .detail {
            color: #caa390;
        }

        }

        .progress-title,
        .earnings-title {
            font-size: 22px;
            color: #784d47;
        }

       

        .share-btn {
            display: flex;
            justify-content: space-around;
            div{
                font-size: 20px;
                color: #520008;
                background-color: #fbc059;
                // width: 90%;
                height: 50px;
                margin: 0 10px;
                padding: 0 20px;
                line-height: 50px;
                text-align: center;
                margin-top: 15px;
                margin-bottom: 15px;
                border-radius: 25px;
                letter-spacing: 2px;
                box-shadow: 0px 5px 3px #fd9d1f;
            }
           
        }
    }
    .style-name{
        padding-top: 10px;
        text-align: center;
    }

}</style>
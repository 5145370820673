import router from '@/router/index'
import i18n from '@/data/i18n/index'
export function navTo (name, params = {}) {
  router.push({
    name,
    params
  })
}

export function toDay(timestamp){
  let date = new Date(timestamp * 1000)
  let y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? "0"+m : m
  let d = date.getDate()
  d = d < 10 ? "0"+d : d
  return `${y}-${m}-${d} `+date.toTimeString().substr(0,8)
}

export function toDay3(timestamp){
  let date = new Date(timestamp * 1000)
  let y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? "0"+m : m
  let d = date.getDate()
  d = d < 10 ? "0"+d : d
  return `${y}-${m}-${d} `
}

export function toDay2(timestamp){
  let date = new Date(timestamp * 1000)
  let m = date.getMonth() + 1
  m = m < 10 ? "0"+m : m
  let d = date.getDate()
  d = d < 10 ? "0"+d : d
  return `${m}-${d} `+date.toTimeString().substr(0,5)
}

export function toCounterTime(time){
  const t = i18n.global.t
  const oneMin = 60
  const oneHour = oneMin * 60
  const oneDay = oneHour * 24
  //天
  let d = Math.floor(time / oneDay)
  //时
  let h = Math.floor(time % oneDay / oneHour)
  //分
  let m = Math.floor(time % oneDay % oneHour / oneMin)
  //秒
  let s = Math.floor(time % oneDay % oneHour % oneMin)

  let res = ""
  if(d){
    res += d+t('common.days')
  }
  if(h){
    res += h+t('common.hours')
  }
  if(m){
    res += m+t('common.minutes')
  }
  res += s+t('common.seconds')
  return res
}

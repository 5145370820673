import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cfg from './common/conf'
import i18n from './data/i18n/index'
// element ui
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.min.mjs'
import en from 'element-plus/dist/locale/en.min.mjs'
// 加载http拦截器
import './common/http'
// composition 数据
import { updateShowPanel } from './data/default'
import { user } from "@/data/token"
//icons
import * as Elicons from "@element-plus/icons-vue";


// 加载token


// 全局过滤器
router.beforeEach((to, from, next) => {
  const path = to.path
  // 判断是否登录状态 如果是就跳过登录
  if (path === '/login' || path === '/stat' || path === '/bigScreen') {
    updateShowPanel(false)
    next()
    return
  }
  updateShowPanel(true)
  if (user.token) {
    next()
  } else {
    next({
      path: '/login'
    })
  }
})

const app = createApp(App)
for (const name in Elicons) {
  app.component(name, Elicons[name]);
}

app.use(i18n)
  .use(ElementPlus, {
    locale: i18n.global.locale == "En" ? en :zhCn,
  })
  .use(store)
  .use(router)
  // .use(i18n)
  .mount('#app')

<template>
  <div class="component">
    <div class="statistic-box">
      <div class="item info">
        <div class="val">
          <span>{{formData.wallet.recharge}}</span>
          <span class="sup">￥</span>
        </div>
        <div class="key">钱包充值</div>
      </div>
      <div class="item info">
        <div class="val">
          <span>{{formData.wallet.consume}}</span>
          <span class="sup">￥</span>
        </div>
        <div class="key">钱包消费</div>
      </div>
      <div class="item success">
        <div class="val">
          <span>{{formData.pile.profit}}</span>
          <span class="sup">天</span>
        </div>
        <div class="key">充电桩 | 盈利</div>
      </div>
      <div class="item success">
        <div class="val">
          <span>{{formData.cabinet.cycle}}</span>
          <span class="sup">天</span>
        </div>
        <div class="key">电柜 | 回本周期</div>
      </div>
      <div class="item warning">
        <div class="val">
          <span>{{formData.wallet.deposit}}</span>
          <span class="sup">￥</span>
        </div>
        <div class="key">押金</div>
      </div>
      <div class="item warning">
        <div class="val">
          <span>{{formData.bike.rent}}</span>
          <span class="sup">￥</span>
        </div>
        <div class="key">车辆租金</div>
      </div>
      <div class="item danger">
        <div class="val">
          <span>{{formData.pile.monthcard}}</span>
          <span class="sup">￥</span>
        </div>
        <div class="key">充电月卡</div>
      </div>
      <div class="item danger">
        <div class="val">
          <span>{{formData.cabinet.monthcard}}</span>
          <span class="sup">￥</span>
        </div>
        <div class="key">换电月卡</div>
      </div>
      <div class="item info">
        <div class="val">
          <span>{{formData.curMonth.powerCost}}</span>
          <span class="sup">￥</span>
        </div>
        <div class="key">本月耗电</div>
      </div>
      <div class="item info">
        <div class="val">
          <span>{{formData.lastMonth.powerCost}}</span>
          <span class="sup">￥</span>
        </div>
        <div class="key">上月耗电</div>
      </div>
      <div class="item success">
        <div class="val">
          <span>{{formData.curMonth.powerCost2}}</span>
          <span class="sup">kW·h</span>
        </div>
        <div class="key">本月耗电</div>
      </div>
      <div class="item success">
        <div class="val">
          <span>{{formData.lastMonth.powerCost2}}</span>
          <span class="sup">kW·h</span>
        </div>
        <div class="key">上月耗电</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from 'vue'
export default {
  name: 'FinanceStatisticBox',
  props: {
    operatorId: {
      type: Number,
      default: 0
    },
    cycle: {
      type: String,
      default: ''
    }
  },
  setup (props, ctx) {
    const wallet = {
      recharge: '1210.11',
      consume: '1122.20',
      deposit: '323.00'
    }
    const pile = {
      profit: 14,
      monthcard: '0.00'
    }
    const cabinet = {
      cycle: 0,
      monthcard: '32.00'
    }
    const bike = {
      rent: '2132.00'
    }
    const curMonth = {
      powerCost: '123.00',
      powerCost2: '24'
    }
    const lastMonth = {
      powerCost: '867.00',
      powerCost2: '21'
    }
    const formData = reactive({
      pile,
      cabinet,
      bike,
      lastMonth,
      curMonth,
      wallet
    })
    // watch
    watch([() => props.operatorId, () => props.cycle], () => {
      console.log('props', props)
    }, { immediate: true })
    return {
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    .statistic-box{
      display: flex;
      flex-wrap: wrap;
      margin-top: 1.25rem;
      >.item{
        width: 24%;
        height: 11rem;
        background-color: #fff;
        box-shadow: $box-shadow;
        border-radius: 0.3rem;
        margin-bottom: 1.33%;
        margin-right: 1.33%;
        color: #fff;
        display: flex;
        flex-direction: column;
        &:nth-child(4n){
          margin-right: 0;
        }
        >.key{
          width: 100%;
          padding: 1rem 0;
          color: $color-txt;
          background-color: #fff;
          border-bottom-left-radius: 0.3rem;
          border-bottom-right-radius: 0.3rem;
          font-size: 0.875rem;
        }
        >.val{
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-size: 3rem;
          >.sup{
            font-size: 1rem;
            margin-left: 0.625rem;
            padding-top: 1.25rem;
          }
        }
        &.info{
          background-color: $color-info;
        }
        &.success{
          background-color: $color-success;
        }
        &.danger{
          background-color: $color-danger;
        }
        &.warning{
          background-color: $color-warning;
        }
      }
    }
  }
</style>

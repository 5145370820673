<template>
  <div class="component">
    <div class="chart-box">
      <div id="chartid-finance-deposit" class="chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { onMounted, reactive } from 'vue'
export default {
  name: 'FinanceDeposit',
  setup () {
    let myChart = null
    const formData = reactive({
    })
    const initChart = () => {
      const chartDom = document.getElementById('chartid-finance-deposit')
      myChart = echarts.init(chartDom)
      updateChart()
    }
    const updateChart = () => {
      const legend = ['车辆押金', '车辆押金退款', '电池押金', '电池押金退款']
      const xAxis = []
      const data = [[], [], [], [], []]
      for (let i = 1; i <= 31; i++) {
        const str = i
        xAxis.push(str)
        data[0].push(0)
        data[1].push(0)
        data[2].push(parseInt(Math.random() * 100))
        data[3].push(parseInt(Math.random() * 100))
      }
      const series = []
      for (const i in data) {
        series.push({
          name: legend[i],
          type: 'line',
          markPoint: {
            data: [
              { type: 'max', name: '最大值' }
            ]
          },
          data: data[i]
        })
      }
      const option = {
        title: {
          text: '押金变动'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: legend
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis
        },
        yAxis: {
          type: 'value'
        },
        series
      }

      option && myChart.setOption(option)
    }

    onMounted(() => {
      initChart()
    })
    return {
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    >.chart-box{
      position: relative;
      >.header{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1000;
      }
      .chart{
        width: 100%;
        height: 22rem;
      }
    }
  }
</style>

<template>
  <div class="home">
    <img class="logo" alt="Vue logo" src="@/assets/logo.png" @click="toAbout">
    <div class="title">{{$t('appname')}}</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { navTo } from '@/common/func'

export default {
  name: 'Home',
  setup () {
    const toAbout = () => {
      console.log('to about')
      navTo(
        'Login',
        {
          title: 'test'
        }
      )
    }
    return {
      toAbout,
    }
  }
}
</script>
<style lang="scss" scoped>
  .home{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logo{
    max-width: 18.75rem;
    margin-top: -6.25rem;
  }
  .title{
    font-size: 1.8rem;
    font-weight: 700;
    margin-top: 1.8rem;
  }
</style>

<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{$t("g.condition")}}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">充值时间</span>
          <el-date-picker
                class="inp"
                v-model="formData.valTime"
                size="small"
                type="daterange"
                unlink-panels
                :range-separator="$t('g.to')"
                :start-placeholder="$t('g.start_date')"
                :end-placeholder="$t('g.end_date')"
                :shortcuts="render.shortcuts"
              >
              </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{$t('g.operator')}}</span>
          <el-select class="inp" size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.operators"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">月卡状态</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.statuses"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">手机号码</span>
          <el-input class="inp" size="small" v-model="formData.valTel" placeholder="请输入手机号码"></el-input>
        </div>

      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{$t('g.search')}}</el-button>
      </div>
    </div>
    <el-alert
        class="el-alert-my"
        type="success">
        <template #title>
          <div class="el-alert-my-txt">
            <span class="tips">提示：</span>
            <span>双击记录查看详情</span>
          </div>
        </template>
    </el-alert>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{$t("g.unit_num")}}:{{formData.total}}，{{$t("g.per_page")}}:{{formData.pageSize}}</span>
        <div class="header-icons">
          <i @click="func.addData" class="el-icon-download header-icon"></i>
          <i @click="func.addData" class="el-icon-plus header-icon"></i>
        </div>
      </div>
      <el-table
            @row-click="func.rowDblClick"
            :data="formData.tableData"
            style="width: 100%">
            <el-table-column
              prop="id"
              label="#"
              width="30">
            </el-table-column>
            <el-table-column
              prop="operator"
              :label="$t('g.agent_code')"
              width="120">
            </el-table-column>
            <el-table-column
              prop="name"
              width="120"
              label="会员">
            </el-table-column>
            <el-table-column
              prop="cardType"
              width="100"
              label="月卡类型">
            </el-table-column>
            <el-table-column
              prop="expire_in"
              label="有效期">
            </el-table-column>
            <el-table-column
              prop="days_left"
              width="120"
              label="剩余天数">
            </el-table-column>
            <el-table-column
              prop="hours_left"
              width="120"
              label="剩余时长(小时)">
            </el-table-column>
            <el-table-column
              prop="status"
              width="80"
              label="状态">
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog
      :title="$t('tips')"
      v-model="formData.dialogVisible"
      width="50%">
      <el-form  label-width="80px" :model="formData.curData">
        <div class="el-form-row">
          <el-form-item label="手机号码">
            <el-input v-model="formData.curData.tel" :disabled="formData.curData.id?true:false"></el-input>
          </el-form-item>
          <el-form-item label="月卡类型">
            <el-select class="inp-block" v-model="formData.curData.cardTypeId" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in render.cardTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('g.agent_code')">
            <el-select class="inp-block" v-model="formData.curData.operatorId" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in render.operators"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="有效天数">
            <el-input v-model="formData.curData.days_left"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="func.submit">{{$t('g.btn_confirm')}}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { operators, updateOperators } from '@/data/default'
export default {
  name: 'ExpensesMonthCard',
  setup () {
    const tableData = [
      {
        id: 1,
        name: '曾先生',
        tel: '13712341234',
        operatorId: 1,
        operator: '广东深圳',
        cardTypeId: 1,
        cardType: '充电',
        expire_in: '2021-12-12 12:12:12',
        days_left: 10,
        hours_left: 240,
        statusId: 1,
        status: '可用'
      }
    ]
    const statuses = [
      {
        label: '全部',
        value: 0
      },
      {
        label: '可用',
        value: 1
      },
      {
        label: '已过期',
        value: 2
      }
    ]
    const render = {
      shortcuts: [
        {
          text: '最近一周',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          }
        }, {
          text: '最近一个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          }
        }, {
          text: '最近三个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          }
        }
      ],
      statuses,
      cardTypes: [
        {
          label: '充电',
          value: 1
        },
        {
          label: '换电',
          value: 2
        }
      ],
      operators
    }
    const formData = reactive({
      tableData,
      curData: {},
      valOperator: 1,
      valStatus: 0,
      valTime: '',
      valTel: '',
      // pagination
      dialogVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 1
    })
    const func = {
      search () {
        console.log('formData', formData)
        updateOperators()
        console.log(operators)
      },
      rowDblClick (row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange (val) {
        console.log('page change', val)
        formData.curPage = val
      },
      addData () {
        formData.dialogVisible = true
        formData.curData = {}
      },
      submit () {
        formData.dialogVisible = false
        console.log('submit', formData.curData)
      }
    }
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .inp-block{
    display: block;
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:1rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color:#969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 4.375rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 0.875rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
}
</style>

<template>
  <div class="component">
    <div class="chart-box">
      <div :id="render.id" class="chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { onMounted, reactive } from 'vue'
export default {
  name: 'FinanceMember',
  props: {
    id: { // 唯一标识，用于图表渲染，防止重复
      type: String,
      default: ''
    }
  },
  setup (props, ctx) {
    let myChart = null
    const id = 'chartid-finance-member' + props.id
    const render = {
      id
    }
    const formData = reactive({
    })
    const initChart = () => {
      const chartDom = document.getElementById(render.id)
      myChart = echarts.init(chartDom)
      updateChart()
    }
    const updateChart = () => {
      const legend = {
        bottom: '0%',
        left: 'center'
      }
      const data = [
        {
          name: '有效',
          value: 7123
        }, {
          name: '异常',
          value: 4873
        }, {
          name: '未使用',
          value: 434
        }
      ]
      const option = {
        title: {
          text: '会员状态'
        },
        tooltip: {
          trigger: 'item'
        },
        legend,
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        series: [
          {
            name: '数量',
            type: 'pie',
            label: {
              formatter: '{b}:{c}({d})%'
            },
            data
          }
        ]
      }

      option && myChart.setOption(option)
    }

    onMounted(() => {
      initChart()
    })
    return {
      formData,
      render
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    >.chart-box{
      position: relative;
      >.header{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1000;
      }
      .chart{
        width: 100%;
        height: 22rem;
      }
    }
  }
</style>

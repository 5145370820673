<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{$t("g.condition")}}</span>
      </div>
      <div class="inp-list">
        <div class="item" v-if="!isOperator()">
          <span class="label">{{$t('g.operator')}}</span>
          <el-select class="inp" size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.operators"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">是否启用</span>
          <el-select class="inp" size="small" v-model="formData.valEnable" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.enables"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">手机号码</span>
          <el-input class="inp" size="small" v-model="formData.valTel" placeholder="请输入手机号码"></el-input>
        </div>
        <div class="item">
          <span class="label">能量卡号</span>
          <el-input class="inp" size="small" v-model="formData.valCardNo" placeholder="请输入能量卡号"></el-input>
        </div>
        <div class="item">
          <span class="label">会员类型</span>
          <el-select class="inp" size="small" v-model="formData.valType" placeholder="请选择v">
              <el-option
                v-for="item in render.types"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{$t('g.search')}}</el-button>
      </div>
    </div>
    <el-alert
        class="el-alert-my"
        type="success">
        <template #title>
          <div class="el-alert-my-txt">
            <span class="tips">提示：</span>
            <span>双击记录查看详情</span>
          </div>
        </template>
    </el-alert>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{$t("g.unit_num")}}:{{formData.total}}，{{$t("g.per_page")}}:{{formData.pageSize}}</span>
        <i @click="func.addData" class="el-icon-plus header-icon"></i>
      </div>
      <el-table
            @row-click="func.rowDblClick"
            :data="formData.tableData"
            style="width: 100%">
            <el-table-column
              prop="id"
              label="#"
              width="30">
            </el-table-column>
            <el-table-column
              prop="operator"
               v-if="!isOperator()"
              :label="$t('g.agent_code')"
              width="180">
            </el-table-column>
            <el-table-column
              prop="tel"
              width="180"
              label="手机号码">
            </el-table-column>
            <el-table-column
              prop="cardno"
              width="180"
              label="会员卡号">
            </el-table-column>
            <el-table-column
              prop="type"
              width="80"
              label="会员类型">
            </el-table-column>
            <el-table-column
              prop="time"
              label="绑定时间">
            </el-table-column>
            <el-table-column
              prop="enable"
              width="80"
              label="是否启用">
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog
      :title="$t('tips')"
      v-model="formData.dialogVisible"
      width="50%">
      <el-form  label-width="80px" :model="formData.curData">
        <div class="el-form-row">
          <el-form-item label="手机号码">
            <el-input v-model="formData.curData.tel" :disabled="formData.curData.id?true:false"></el-input>
          </el-form-item>
          <el-form-item label="能量卡号">
            <el-input v-model="formData.curData.cardno"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('g.agent_code')">
            <el-select class="inp-block" v-model="formData.curData.operatorId" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in render.operators"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="会员类型">
            <el-select class="inp-block" v-model="formData.curData.typeId" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in render.addTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        </div>
        <el-form-item label="是否启用">
          <el-select class="inp-block" v-model="formData.curData.enableId" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.addEnables"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="func.submit">{{$t('g.btn_confirm')}}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { isOperator } from '@/data/token'
export default {
  name: 'MemberCards',
  setup () {
    const tableData = [
      {
        id: 1,
        time: '2021-08-11 12:12:12',
        cardno: '20181212',
        tel: '13712341234',
        operatorId:1,
        operator: '广东深圳',
        typeId:1,
        type:'充电',
        enableId:1,
        enable:'是'
      }
    ]
    const enables = [
      {
        label: '所有状态',
        value: 0
      },
      {
        label: '是',
        value: 1
      },
      {
        label: '否',
        value: 2
      }
    ]
    const types = [
      {
        label: '全部',
        value: 0
      }, {
        label: '充电',
        value: 1
      }, {
        label: '换电',
        value: 2
      }
    ]
    const render = {
      enables,
      operators:[
        {
          label:'广东深圳',
          value:1
        }
      ],
      addEnables: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 2
        }
      ],
      addTypes: [
        {
          label: '充电',
          value: 1
        }, {
          label: '换电',
          value: 2
        }
      ],
      types,
    }
    const formData = reactive({
      tableData,
      curData: {},
      valOperator: 1,
      valEnable: 0,
      valType: 0,
      valTel:'',
      valCardNo:'',
      //pagination
      dialogVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 1,
    })
    const func = {
      search () {
        console.log('formData', formData)
      },
      rowDblClick (row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange (val) {
        console.log('page change', val)
        formData.curPage = val
      },
      addData () {
        formData.dialogVisible = true
        formData.curData = {
          cardno: '',
          tel: '',
          operatorId:1,
          typeId:1,
          enableId:1,
        }
      },
      submit () {
        formData.dialogVisible = false
        console.log('submit', formData.curData)
      }
    }
    return {
      func,
      render,
      formData,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .inp-block{
    display: block;
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:1rem;
        font-weight: 500;
      }
      >.header-icon{
        color: pointer;
        position: absolute;
        right: 10px;
        top: 0px;
        font-size: 0.875rem;
        font-weight: 500;
        border: 1px solid #ddd;
        color:#969696;
        padding: 0.25rem;
        border-radius: 50%;
        z-index: 100;
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 4.375rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 0.875rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
}
</style>

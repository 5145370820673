<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{$t('decision.stat_condition')}}</span>
      </div>
      <div class="inp-list">
        <div class="item" v-if="!isOperator()">
          <span class="label">{{$t('g.operator')}}</span>
          <el-select size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.operators"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">{{$t('decision.cycle')}}</span>
          <el-select size="small" v-model="formData.valCircle" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.circles"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">{{$t('decision.time')}}</span>
          <el-date-picker
            size="small"
            v-model="formData.valTime"
            :type="formData.valCircle==1?'year':'month'"
            :placeholder="$t('g.choose')">
          </el-date-picker>
        </div>
      </div>
    </div>
    <FinanceStatisticBox :operatorId="formData.valOperator" :cycle="formData.cycle"/>
    <FinanceOrder class="card-box"/>
    <FinanceDeposit class="card-box"/>
    <div class="box-2">
      <!-- <OrderStatus id="1" class="card-box"/> -->
      <FinanceMember class="card-box"/>
      <FinanceOperators class="card-box"/>
    </div>
    <FinancePower class="card-box"/>

  </div>
</template>

<script>
import { reactive, watch, onMounted } from 'vue'
import axios from 'axios'
import FinanceStatisticBox from '@/components/finance/StatisticBox.vue'
import FinanceOrder from '@/components/charts/FinanceOrder.vue'
import FinanceDeposit from '@/components/charts/FinanceDeposit.vue'
import FinancePower from '@/components/charts/FinancePower.vue'
import FinanceMember from '@/components/charts/FinanceMember.vue'
import FinanceOperators from '@/components/charts/FinanceOperators.vue'
import { operators } from '@/data/default'
import i18n from '@/data/i18n/index'
import { isOperator } from '@/data/token'

export default {
  name: 'FinanceReport',
  components: {
    FinanceStatisticBox,
    FinanceOrder,
    FinancePower,
    FinanceMember,
    FinanceOperators,
    FinanceDeposit
  },
  setup () {
    const t  = i18n.global.t
    
    const circles = [
      {
        label: t('decision.cycles.by_year'),
        value: 1
      }, {
        label: t('decision.cycles.by_month'),
        value: 2
      }
    ]
    const render = {
      operators,
      circles
    }
    const formData = reactive({
      valOperator:0,
      valCircle:2,
      cycle: '',
      valTime:new Date()
    })
    // watch
    watch(() => formData.valTime, () => {
      if (formData.valCircle === 1) {
        formData.cycle = formData.valTime.getFullYear() + ''
      } else {
        formData.cycle = formData.valTime.getFullYear() + '-' + (formData.valTime.getMonth() + 2)
      }
    }, { immediate: true })
    onMounted(async ()=>{
      try{
        let params = {
          startAt:1609430400,
          endAt:1640966400,
          type:2,
        }
        let res = await axios.get("/admin/stat/report",{
          params
        })
      }catch(e){

      }
    })
    return {
      formData,
      render,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
  .content{
    box-sizing: border-box;
    padding: 1.25rem;
    margin-bottom: 6.25rem;
    .card-box{
      font-size: 0.875rem;
      background-color: #fff;
      box-shadow: $box-shadow;
      box-sizing: border-box;
      padding: 1.25rem;
      border-radius: 0.3rem;
      margin-bottom: 1.25rem;
      .header{
        text-align:left;
        margin-bottom: 1.25rem;
        >.title{
          font-size:1rem;
          font-weight: 500;
        }
      }
      .inp-list{
        display: flex;
        align-items: center;
        >.item{
          margin-right: 3rem;
          .label{
            margin-right: 0.625rem;
          }
        }
      }
    }
    >.el-alert-my{
      margin-top: 1.25rem;
      box-shadow: $box-shadow;
      padding: 1.25rem;
      .el-alert-my-txt{
        font-size: 0.875rem;
        >.tips{
          font-weight: bold;
        }
      }
      :deep(.el-alert__closebtn){
        top: 1.25rem;
        font-size: 1rem;
      }
    }
    >.box-2{
      display: flex;
      justify-content: space-between;
      >.card-box{
        width: 49%;
      }
    }
  }
</style>

<template>
  <div class="stat-body">
    <div class="title">{{$t('appname')}}</div>
    <div class="box">
      <div class="left">
        <div class="item">
          <div class="title">用户数据统计</div>
          <div class="item-body ib1">
            <div class="li l1">
              <div class="label">注册人数</div>
              <div class="value">6</div>
            </div>
            <div class="li l2">
              <div class="label">缴纳押金人数</div>
              <div class="value">6</div>
            </div>
            <div class="li l3">
              <div class="label">近7天注册人数</div>
              <div class="value">6</div>
            </div>
            <div class="li l4">
              <div class="label">即将到期人数</div>
              <div class="value">6</div>
            </div>
            <div class="li l5">
              <div class="label">到期续费人数</div>
              <div class="value">6</div>
            </div>
            <div class="li l6">
              <div class="label">最近7天活跃人数</div>
              <div class="value">6</div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="title">订单报表</div>
        </div>
        <div class="item item-lb">
          <div class="title">运营统计</div>
          <div class="item-body ib3">
            <div class="li l1">
              <div class="label">订单总数</div>
              <div class="value">6</div>
            </div>
            <div class="li l2">
              <div class="label">上周订单数</div>
              <div class="value">6</div>
            </div>
            <div class="li l3">
              <div class="label">同比增长</div>
              <div class="value">6</div>
            </div>
            <div class="li l4">
              <div class="label">上周活跃度</div>
              <div class="value">6</div>
            </div>
            <div class="li l5">
              <div class="label">本周活跃度</div>
              <div class="value">6</div>
            </div>
            <div class="li l6">
              <div class="label">同比增长</div>
              <div class="value">6</div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="map"></div>
        <div class="item item-m">
          <div class="title">近30天套餐TOP5</div>
        </div>
      </div>
      <div class="right">
        <div class="item item-r">
          <div class="title">设备数据统计</div>
          <div class="item-body ib2">
            <div class="li l1">
              <div class="label">设备数量</div>
              <div class="value">6</div>
            </div>
            <div class="li l2">
              <div class="label">空闲设备数量</div>
              <div class="value">6</div>
            </div>
            <div class="li l3">
              <div class="label">异常设备数量</div>
              <div class="value">6</div>
            </div>
            <div class="li l4">
              <div class="label">电池数量</div>
              <div class="value">6</div>
            </div>
            <div class="li l5">
              <div class="label">空闲电池数</div>
              <div class="value">6</div>
            </div>
            <div class="li l6">
              <div class="label">租赁中电池数量</div>
              <div class="value">6</div>
            </div>
          </div>
        </div>
        <div class="item item-r">
          <div class="title">充电柜频率</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { navTo } from '@/common/func'

export default {
  name: 'Stat',
  setup () {
    const toAbout = () => {
      console.log('to about')
      navTo(
        'Login',
        {
          title: 'test'
        }
      )
    }
    return {
      toAbout,
    }
  }
}
</script>
<style lang="scss" scoped>
  .stat-body{
    background-image: url(../assets/img/stat/bg.png);
    background-size: 100% 100%;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    font-size: 1.171vw;
    >.title{
      font-size: 1.4vw;
      color: #fff;
      height: 7vh;
      line-height: 7vh;
      font-weight: 700;
    }
    >.box{
      display: flex;
      margin-bottom: 1vh;
      >div{
        width: 33.33%;
      }
      >.left{
      }
      >.right{
      }
      >.middle{
        >.map{
          height: 52vh;
        }
      }
      .item{
        position: relative;
        background-image: url(../assets/img/stat/title.png);
        background-size: 100% 100%;
        width: 98%;
        height: 30vh;
        margin-bottom: 1vh;
        margin: 0 0 0 auto;
        box-sizing: border-box;
        >.title{
          position: absolute;
          left: 4vw;
          top: 6%;
          font-size: 1.1vw;
          font-weight: 700;
          color: #fff;
        }
        >.item-body{
          display: flex;
          flex-wrap: wrap;
          padding-top: 10%;
        }
        >.ib1{
          >.li{
            width: 33.33%;
            margin-bottom: 2%;
            >.label{
              color: #fff;
              font-size: 0.7vw;
              font-weight: 700;
              margin-bottom: 4px;
            }
            >.value{
              margin: 0 auto;
              margin-top: 0.5vh;
              width: 60%;
              height: 8vh;
              line-height: 8vh;
              background-size: 100% 100%;
              font-weight: 700;
            }
            &.l1{
              >.value{
                background-image: url(../assets/img/stat/l1.png);
                color:#0967ee;
              }
            }
            &.l2{
              >.value{
                background-image: url(../assets/img/stat/l2.png);
                color:#e8e006;
              }
            }
            &.l3{
              >.value{
                background-image: url(../assets/img/stat/l3.png);
                color:#00c30c;
              }
            }
            &.l4{
              >.value{
                background-image: url(../assets/img/stat/l4.png);
                color:#6a2fce;
              }
            }
            &.l5{
              >.value{
                background-image: url(../assets/img/stat/l5.png);
                color:#ffa173;
              }
            }
            &.l6{
              >.value{
                background-image: url(../assets/img/stat/l6.png);
                color:red;
              }
            }
          }
        }
        >.ib2{
          >.li{
            width: 40%;
            height: 8vh;
            margin: 0 5%;
            margin-top: 4%;
            background-size: 100% 100%;
            box-sizing: border-box;
            font-weight: 700;
            position: relative;
            >.label{
              color: #fff;
              font-size: 0.7vw;
              position: absolute;
              left: 55%;
              right: 10%;
            }
            >.value{
              position: absolute;
              bottom: 3%;
              right: 30%;
            }
            &.l1{
                background-image: url(../assets/img/stat/r1.png);
                color:#e8e006;
            }
            &.l2{
                background-image: url(../assets/img/stat/r2.png);
                color:#ffa173;
            }
            &.l3{
                background-image: url(../assets/img/stat/r3.png);
                color:red;
            }
            &.l4{
                background-image: url(../assets/img/stat/r4.png);
                color:#6a2fce;
            }
            &.l5{
                background-image: url(../assets/img/stat/r5.png);
                color:#0967ee;
            }
            &.l6{
                background-image: url(../assets/img/stat/r6.png);
                color:#00c30c;
            }
          }
        }
        &.item-lb{
          height: 30vh;
        }
        &.item-r{
          height: 45vh;
          >.title{
            top: 8%;
          }
        }
        &.item-m{
          height: 38vh;
          >.title{
            top: 7%;
          }
        }
      }
    }
  }
</style>

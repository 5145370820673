<template>
  <div class="content">
    <div id="map"></div>
    <div class="map-filter">
      <el-select class="e-select" v-model="formData.search" filterable placeholder="请输入车牌号查询">
          <el-option
            v-for="item in formData.data"
            :key="item.id"
            :label="item.code"
            :value="item.id">
          </el-option>
      </el-select>
    </div>
    <div class="map-options">
      <el-switch v-model="formData.online" active-color="#13ce66">
      </el-switch>
      <span class="switch-txt switch-txt-online">在线</span>
      <el-switch v-model="formData.offline" active-color="#ff4949">
      </el-switch>
      <span class="switch-txt switch-txt-offline">离线</span>
    </div>
    <div class="eq-box-ct" v-show="formData.showEQBox" @click="func.doHideEQBox">
      <div class="eq-box" @click.stop="">
        <div class="title">{{formData.curData.code}}</div>
        <div class="item">
          <span class="label">状态:</span>
          <span :class="{'status':true,'val':true,'active':formData.curData.status==='在线'}">{{formData.curData.status}}</span>
        </div>
        <div class="item">
          <span class="label">电量:</span>
          <span class="val">
            <div class="progress">
              <el-progress :text-inside="true" :stroke-width="20" :percentage="formData.curData.energy" status="exception"></el-progress>
            </div>
          </span>
        </div>
        <div class="item">
          <span class="label">温度:</span>
          <span class="val">{{formData.curData.temperature}}</span>
        </div>
        <div class="item">
          <span class="label">型号:</span>
          <span class="val">{{formData.curData.model}}</span>
        </div>
        <div class="item">
          <span class="label">激活时间:</span>
          <span class="val">{{formData.curData.activationTime}}</span>
        </div>
        <div class="item">
          <span class="label">品牌:</span>
          <span class="val">{{formData.curData.brand}}</span>
        </div>
        <div @click="func.seeMore" class="see-more">查看更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import { batteryData } from '@/data/MapDataSample'
import ClusterBubble from '@/data/ClusterBubble'
import { onMounted, watch, onBeforeUnmount, reactive } from 'vue'
import { getCoord } from '@/data/default'
export default {
  name: 'OperationBikes',
  setup () {
    const TMap = window.TMap
    let map = null
    let marker = null
    let markerCluster = null
    let markerGeometries = []
    let clusterBubbleList = []
    let data = []
    data = batteryData

    const formData = reactive({
      search: '',
      data,
      online: true,
      offline: true,
      curData: {},
      showEQBox: false
    })
    const func = {}

    func.seeMore = () => {

    }
    func.doHideEQBox = () => {
      formData.showEQBox = false
      formData.search = ''
      for (const idx in formData.data) {
        formData.data[idx].active = false
      }
      // 还原当前marker
      for (const i in markerGeometries) {
        if (markerGeometries[i].styleId.indexOf('Atv') !== -1) {
          const styleId = markerGeometries[i].styleId
          markerGeometries[i].styleId = styleId.substr(0, styleId.length - 3)
        }
      }
      marker.updateGeometries(markerGeometries)
    }
    const doShowEQBox = (id, focus = true) => {
      for (const idx in formData.data) {
        const item = formData.data[idx]

        if (item.id === id) {
          formData.curData = item
          formData.showEQBox = true
          formData.data[idx].active = true
          if (focus) {
            map.setCenter(new TMap.LatLng(item.lat, item.lng))
            map.setZoom(12)
            return
          }
          // 放大当前marker
          for (const i in markerGeometries) {
            if (markerGeometries[i].id === id) {
              // logic
              if (markerGeometries[i].styleId.indexOf('Atv') === -1) {
                markerGeometries[i].styleId += 'Atv'
              }
            } else {
              if (markerGeometries[i].styleId.indexOf('Atv') !== -1) {
                const styleId = markerGeometries[i].styleId
                markerGeometries[i].styleId = styleId.substr(0, styleId.length - 3)
              }
            }
          }
          marker.updateGeometries(markerGeometries)
        }
      }
    }
    const getDataById = (id) => {
      for (const item of formData.data) {
        if (item.id === id) {
          return item
        }
      }
      return {}
    }
    const dataFilter = () => {
      const res = []
      for (const item of data) {
        if (formData.online && item.status === '在线') {
          res.push(item)
        } else if (formData.offline && item.status === '离线') {
          res.push(item)
        }
      }
      formData.data = res
      // 更新地图标记
      const geometries = []
      for (const item of formData.data) {
        geometries.push({
          id: item.id,
          position: new TMap.LatLng(item.lat, item.lng)
        })
      }
      markerCluster.setGeometries(geometries)
    }

    watch([
      () => formData.online,
      () => formData.offline
    ], () => {
      dataFilter()
    })
    watch(() => formData.search, () => {
      if (formData.search) {
        doShowEQBox(formData.search)
      }
    })
    const initMap = async () => {
      const coord = await getCoord()
      const center = new TMap.LatLng(coord.lat, coord.lng)
      const features = ['base', 'building3d', 'point']
      // 初始化地图
      map = new TMap.Map('map', {
        zoom: 12, // 设置地图缩放级别
        pitch: 40, // 设置地图俯仰角
        center, // 设置地图中心点坐标
        mapStyleId: 'style1', // 个性化样式
        baseMap: {
          type: 'vector',
          features
        }
      })
      // 点数据
      const geometries = []
      for (const item of formData.data) {
        geometries.push({
          id: item.id,
          position: new TMap.LatLng(item.lat, item.lng)
        })
      }
      // 创建点聚合实例
      markerCluster = new TMap.MarkerCluster({
        id: 'cluster',
        map: map,
        enableDefaultStyle: false, // 启用默认样式
        minimumClusterSize: 2, // 形成聚合簇的最小个数
        geometries,
        zoomOnClick: true, // 点击簇时放大至簇内点分离
        gridSize: 60, // 聚合算法的可聚合距离
        averageCenter: false, // 每个聚和簇的中心是否应该是聚类中所有标记的平均值
        maxZoom: 10 // 采用聚合策略的最大缩放级别
      })
      dataFilter()
      markerCluster.on('cluster_changed', (e) => {
        // 销毁旧聚合簇生成的覆盖物
        if (clusterBubbleList.length) {
          clusterBubbleList.forEach(function (item) {
            item.destroy()
          })
          clusterBubbleList = []
        }
        markerGeometries = []
        // 根据新的聚合簇数组生成新的覆盖物和点标记图层
        const clusters = markerCluster.getClusters()
        clusters.forEach(function (item) {
          if (item.geometries.length > 1) {
            const clusterBubble = new ClusterBubble({
              map,
              position: item.center,
              content: item.geometries.length
            })
            clusterBubble.on('click', () => {
              map.fitBounds(item.bounds)
            })
            clusterBubbleList.push(clusterBubble)
          } else {
            const id = item.geometries[0].id
            const data = getDataById(id)
            let styleId = data.status === '在线' ? 'style1' : 'style2'
            if (data.active) {
              styleId += 'Atv'
            }
            markerGeometries.push({
              id,
              styleId,
              position: item.geometries[0].position
            })
          }
        })
        // 更新marker
        if (marker) {
          marker.setGeometries(markerGeometries)
        } else {
          marker = new TMap.MultiMarker({
            map,
            styles: getMarkerStyle(),
            markerGeometries
          })
          marker.on('click', (e) => {
            doShowEQBox(e.geometry.id, false)
          })
        }
      })
    }
    const getMarkerStyle = () => {
      const style1 = new TMap.MarkerStyle({
        width: 32,
        height: 41,
        src: require('@/assets/img/bike-online.png')
      })
      const style1Atv = new TMap.MarkerStyle({
        width: 48,
        height: 62,
        src: require('@/assets/img/bike-online.png')
      })
      const style2 = new TMap.MarkerStyle({
        width: 32,
        height: 41,
        src: require('@/assets/img/bike-offline.png')
      })
      const style2Atv = new TMap.MarkerStyle({
        width: 48,
        height: 62,
        src: require('@/assets/img/bike-offline.png')
      })
      return {
        style1,
        style1Atv,
        style2,
        style2Atv
      }
    }
    onMounted(() => {
      initMap()
    })
    onBeforeUnmount(() => {
      // 销毁地图，防止卡顿
      map.destroy()
    })

    return {
      formData,
      func
    }
  }
}
</script>
<style lang="scss" scoped>
  .content{
    position:relative;
    height: 100%;
    #map{
      width: 100%;
      // height: 100%;
      height: calc(100% - 30px);
    }
  }
  .map-filter{
    position: absolute;
    top:1.25rem;
    left: 1.25rem;
    z-index: 9999;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 4rem;
    .e-select{
      width: 20rem;
    }
  }
  .map-options{
    position: absolute;
    bottom:5.5rem;
    right:1.25rem;
    background:#fff;
    z-index:9999;
    padding: 0.275rem 0.8rem;
    border-radius:0.5rem;
    display: flex;
    align-items: center;
    .switch-txt{
      font-size: 0.875rem;
      margin-left: 0.625rem;
      color: #606266;
      font-weight: 500;
      &.switch-txt-online{
        margin-right: 1.25rem;
      }
      &.switch-txt-offline{

      }
    }
  }
  .eq-box-ct{
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    .eq-box{
      margin-left: 5.25rem;
      margin-top: 4rem;
      background-color: #fff;
      width: 20rem;
      border-radius: 0.3125rem;
      box-sizing: border-box;
      padding: 0.75rem;
      .chart{
        width: 14rem;
        height: 16rem;
        margin: 0 auto;
      }
      .title{
        font-size: 1.2rem;
        padding: 0.9375rem 0;
      }
      .item{
        text-align: left;
        margin-bottom: 0.625rem;
        font-size: 0.875rem;
        .label{
          font-weight: 500;
          margin-right: 0.5rem;
          &:nth-child(3){
            margin-left: 3.125rem;
          }
        }
        .val{
          >.progress{
            width: 12.5rem;
            display: inline-block;
            vertical-align: middle;
          }
          &.status{
            color: #F56C6C;
          }
          &.active{
            color: #67C23A;
          }
        }
      }
      .see-more{
        text-align:right;
        cursor: pointer;
        color: royalblue;
        font-size: 0.8125rem;
      }
    }
  }
</style>

import { createI18n } from 'vue-i18n'
import chs from './chs'
import eng from './eng'
import storage from '@/common/storage'

const locale = storage.get('locale') || '中文'
const i18n = createI18n({
  locale,
  messages:{
    "中文":chs,
    "En":eng
  }
})

export default i18n

<template>
  <div class="content">
    <div id="map"></div>
    <div class="map-filter">
      <div class="sl-label">运营商</div>
      <el-select v-model="formData.operatorVal" placeholder="">
          <el-option
            v-for="item in formData.operators"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
      </el-select>
      <div class="sl-label">昵称</div>
      <el-select class="e-select" v-model="formData.search" filterable placeholder="请输入昵称查询">
          <el-option
            v-for="item in formData.data"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
      </el-select>
    </div>
   <!-- <div class="map-options">
      <el-switch v-model="formData.online" active-color="#13ce66">
      </el-switch>
      <span class="switch-txt switch-txt-online">在线</span>
      <el-switch v-model="formData.offline" active-color="#ff4949">
      </el-switch>
      <span class="switch-txt switch-txt-offline">离线</span>
    </div> -->
  </div>
</template>

<script>
import { onMounted, watch, onBeforeUnmount, reactive,computed } from 'vue'
import { getCoord,operatorList } from '@/data/default'
import axios from 'axios'
import { toDay } from '@/common/func'
export default {
  name: 'OperationScatter',
  setup () {
    const TMap = window.TMap
    let map = null
    let markerCluster = null
    const formData = reactive({
      search: '',
      data:[],
      operators:operatorList,
      operatorVal:operatorList[0]?operatorList[0].value : ""
    })
    const func = {}

    const getDataById = (id) => {
      for (const item of formData.data) {
        if (item.id === id) {
          return item
        }
      }
      return {}
    }

    watch(() => formData.search, () => {
      if (formData.search) {
        const item = getDataById(formData.search)
        map.setCenter(new TMap.LatLng(item.lat, item.lng))
        map.setZoom(12)
      }
    })
    watch(() => formData.operatorVal, () => {
      loadData()
    })
    const loadData = async ()=>{
      console.log("loadData")
      if(!formData.operatorVal)return;
      try{
        let res = await axios.get('/admin/user?getAll=1&agentcode='+formData.operatorVal)
        let data = []
        for(let item of res.List){
          let tmp = {
            id:item.Id,
            name: item.Nickname,
            lat: item.Latitude,
            lng: item.Longitude,
            time: toDay(item.CreatedAt),
          }
          data.push(tmp)
        }
        formData.data = data
        //更新地图位置
        const geometries = []
        for (const item of formData.data) {
          geometries.push({
            id: item.id,
            position: new TMap.LatLng(item.lat, item.lng)
          })
        }
        markerCluster.setGeometries(geometries)
      }catch(e){

      }
    }
    const initMap = async () => {
      const coord = await getCoord()
      const center = new TMap.LatLng(coord.lat, coord.lng)
      const features = ['base', 'building3d', 'point']
      // 初始化地图
      map = new TMap.Map('map', {
        zoom: 12, // 设置地图缩放级别
        pitch: 40, // 设置地图俯仰角
        center, // 设置地图中心点坐标
        mapStyleId: 'style1', // 个性化样式
        baseMap: {
          type: 'vector',
          features
        }
      })
      // 点数据
      const geometries = []
      for (const item of formData.data) {
        geometries.push({
          id: item.id,
          position: new TMap.LatLng(item.lat, item.lng)
        })
      }
      // 创建点聚合实例
      markerCluster = new TMap.MarkerCluster({
        id: 'cluster',
        map: map,
        enableDefaultStyle: true, // 启用默认样式
        minimumClusterSize: 2, // 形成聚合簇的最小个数
        geometries,
        zoomOnClick: true, // 点击簇时放大至簇内点分离
        gridSize: 60, // 聚合算法的可聚合距离
        averageCenter: false, // 每个聚和簇的中心是否应该是聚类中所有标记的平均值
        // maxZoom: 10 // 采用聚合策略的最大缩放级别
      })
    }
    onMounted(async () => {
      await initMap()
      loadData()
    })
    onBeforeUnmount(() => {
      // 销毁地图，防止卡顿
      map.destroy()
    })

    return {
      formData,
      func
    }
  }
}
</script>
<style lang="scss" scoped>
  .content{
    position:relative;
    height: 100%;
    #map{
      width: 100%;
      // height: 100%;
      height: calc(100% - 30px);
    }
  }
  .map-filter{
    position: absolute;
    top:1.25rem;
    left: 1.25rem;
    z-index: 9999;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 4rem;
    .sl-label{
      margin:0 0.625rem;
      font-size: 0.875rem;
    }
    .e-select{
      width: 14rem;
    }
  }
  .map-options{
    position: absolute;
    bottom:5.5rem;
    right:1.25rem;
    background:#fff;
    z-index:9999;
    padding: 0.275rem 0.8rem;
    border-radius:0.5rem;
    display: flex;
    align-items: center;
    .switch-txt{
      font-size: 0.875rem;
      margin-left: 0.625rem;
      color: #606266;
      font-weight: 500;
      &.switch-txt-online{
        margin-right: 1.25rem;
      }
      &.switch-txt-offline{

      }
    }
  }
</style>

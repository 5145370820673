<template>
  <div class="component">
    <div class="logo" @click="func.navTo('OperationUsers')">
      <img src="@/assets/logo.png" />
      <span>{{ $t('appname') }}</span>
    </div>
    <div class="fast-btn">
      <el-button @click="func.toggleMainMenu" class="btn" size="small" type="primary" icon="More" circle></el-button>
      <el-button @click="func.toggleFastIcon" class="btn" size="small" type="primary" icon="Warning" circle></el-button>
    </div>
    <div class="icon-list">
      <div style="margin-right: 20px;" v-if="!isOperator()"><a target="_blank" href="/bigScreen"
          style="text-decoration: none;color:#409eff;font-size:20px">大屏</a></div>
      <HeaderPopup :key="idx" v-for="(item, idx) in 3" :type="idx" class="item" />
      <div class="locale-changer">
        <el-select size="small" v-model="curLocale">
          <el-option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale
            }}</el-option>
        </el-select>
      </div>
      <div class="user" @click="func.logOut">
        <span>{{ user.name || $t("login.unlogin") }}</span>
        <img src="@/assets/logo.png" />
      </div>
    </div>
    <div class="fast-icon-list" v-show="showFastIconList">
      <!-- <HeaderPopup :key="idx" v-for="(item,idx) in 3" :type="idx" class="item"/> -->
    </div>
  </div>
</template>

<script>
import HeaderPopup from '@/components/common/HeaderPopup.vue'
import { navTo } from '@/common/func'
import { ElMessageBox, ElMessage } from 'element-plus'
import { toggleMainMenu } from '@/data/default'
import { ref, watch } from 'vue'
import { user } from '@/data/token'
import { isOperator } from '@/data/token'
import axios from 'axios'
import i18n from '@/data/i18n/index'
import storage from '@/common/storage'

export default {
  name: 'PublicHeader',
  components: {
    HeaderPopup
  },
  setup() {
    const curLocale = ref(storage.get('locale') || '中文')
    watch(curLocale, (newVal, oldVal) => {
      storage.set('locale', curLocale.value)
      window.location.reload()
    })
    const showFastIconList = ref(false)
    const t = i18n.global.t
    const func = {
      toggleMainMenu,
      toggleFastIcon() {
        showFastIconList.value = !showFastIconList.value
      }
    }
    func.navTo = navTo
    func.logOut = () => {
      ElMessageBox.confirm(t('login.confirm_logout'), t('tips'), {
        confirmButtonText: t('confirm'),
        cancelButtonText: t('cancel'),
        type: 'warning'
      })
        .then(() => {
          return axios.get("/admin/login/loginOut")
        })
        .then(() => {
          navTo('Login')
        })
        .catch(() => {
        })
    }
    return {
      curLocale,
      func,
      showFastIconList,
      user,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.component {
  display: flex;
  align-items: center;
  padding: 0.625rem 1.875rem;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;

    >img {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.625rem;
    }
  }

  .fast-btn {
    display: none;
  }

  .icon-list {
    display: flex;
    align-items: center;

    .item {
      margin-right: 2.5rem;
    }

    .locale-changer {
      margin-right: 1rem;
      width: 6rem;
      text-align: center;
    }

    .user {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: #3f51b5;
      }

      >span {
        margin-right: 1.25rem;
        font-weight: bold;
      }

      >img {
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 50%;
        border: 3px solid #fff;
      }
    }
  }
}

@media screen and (max-width:800px) {
  .component {
    flex-wrap: wrap;

    .logo {
      display: none;
    }

    .fast-btn {
      display: inline-block;

      >.btn {
        font-weight: bold;
        font-weight: bold;
        font-size: 1rem;
        padding: 0.375rem;
      }
    }

    .icon-list {
      .item {
        display: none;
      }
    }

    .fast-icon-list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.25rem;
    }
  }
}
</style>
